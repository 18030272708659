export class AsyncCommandStatus {
  constructor(
    storageObject = {
      percentComplete: 0,
      time: new Date(),
      error: null,
      data: null,
    },
  ) {
    this.percentComplete = storageObject?.percentComplete || 0
    this.time = storageObject?.time || new Date()
    this.error = storageObject?.error || null
    this.data = storageObject?.data || null
  }

  toJson() {
    return {
      percentComplete: this.percentComplete,
      time: this.time,
      error: this.error,
      data: this.data || null,
    }
  }
}
