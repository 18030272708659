var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "upload-progress d-flex flex-column",
    class: {
      selected: _vm.selected,
      highlighted: _vm.highlighted
    }
  }, [_c('div', {
    staticClass: "text-center px-2"
  }, [_c('div', {
    staticClass: "text-overline pa-2",
    staticStyle: {
      "position": "relative",
      "font-weight": "600"
    }
  }, [_c('span', {
    staticClass: "font-weight-bold",
    staticStyle: {
      "font-size": "16px"
    }
  }, [_vm._v("File Upload – "), _c('strong', {
    staticStyle: {
      "font-size": "18px"
    }
  }, [_vm._v(_vm._s(_vm.percentComplete) + "%")]), _c('div', {
    staticStyle: {
      "position": "absolute",
      "top": "6px",
      "right": "1px"
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": "",
      "small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('cancel-upload');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "rgba(0,0,0,0.3)",
      "size": "20"
    }
  }, [_vm._v("mdi-close")])], 1)], 1)])]), _c('v-divider'), _c('div', {
    staticClass: "d-flex font-weight-bold py-1",
    staticStyle: {
      "font-size": "18px"
    }
  }, [_c('div', {
    staticClass: "flex-grow-1"
  }), _c('div', [_vm._v("“")]), _c('div', {
    staticClass: "min-width-0 text-truncate",
    attrs: {
      "title": _vm.file.name
    }
  }, [_vm._v(" " + _vm._s(_vm.middleTruncateText(_vm.file.name, 25, 2 / 3)) + " ")]), _c('div', [_vm._v("”")]), _c('div', {
    staticClass: "flex-grow-1"
  })])], 1), _c('div', {
    staticClass: "min-width-0 text-center flex-grow-1"
  }, [_c('div', {
    staticClass: "d-flex justify-center",
    staticStyle: {
      "position": "relative",
      "height": "100%"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column align-center justify-center"
  }, [_c('div', {
    staticClass: "d-flex justify-center"
  }, [_c('FileUploadMeter', {
    attrs: {
      "file": _vm.file
    }
  })], 1)])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }