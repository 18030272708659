import { mergeAttributes, Node } from '@tiptap/core'
import { Selection, TextSelection } from 'prosemirror-state'

export function createNoteBoardNoteExtension(nodeViewRenderer) {
  const opts = {
    name: 'noteBoardNote',
    group: 'block',
    inline: false,
    draggable: true,
    selectable: true,

    parseHTML() {
      return [
        {
          tag: 'note-board-note',
        },
      ]
    },

    renderHTML({ HTMLAttributes }) {
      return ['note-board-note', mergeAttributes(HTMLAttributes)]
    },

    addAttributes() {
      // Return an object with attribute configuration
      return {
        'note-id': {
          default: null,
        },
        'note-board-id': {
          default: null,
        },
        'research-notebook-id': {
          default: null,
        },
        'research-source-id': {
          default: null,
        },
      }
    },
    addKeyboardShortcuts() {
      return {
        ArrowRight: ({ editor }) => createNewLineIfNeeded(editor, opts),
        ArrowDown: ({ editor }) => createNewLineIfNeeded(editor, opts),
      }
    },
  }
  if (nodeViewRenderer) {
    opts.addNodeView = () => nodeViewRenderer
  }
  return Node.create(opts)
}

const createNewLineIfNeeded = (editor, opts) => {
  const view = editor.view
  const state = view.state
  const selection = state.selection

  const { $from } = selection
  if (selection.node?.type.name !== opts.name) {
    return false
  }

  const from = Selection.findFrom(state.doc.resolve($from.pos), 1, true)
  if (!from) {
    if ($from.depth > 0) {
      const fromAfter = $from.after()
      const transaction = state.tr.insert(
        fromAfter,
        state.schema.nodes.hardBreak.create(),
      )
      transaction.setSelection(
        TextSelection.create(transaction.doc, fromAfter + 1),
      )
      view.dispatch(transaction)
    } else {
      const transaction = state.tr.insert(
        state.doc.content.size,
        state.schema.nodes.paragraph.create(),
      )
      transaction.setSelection(
        TextSelection.create(transaction.doc, state.doc.content.size + 1),
      )
      view.dispatch(transaction)
    }
    return true
  }
  return false
}
